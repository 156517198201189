import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  product: null,
  products: null,
  normal_products: null,
  rental_products: null,
  loading: false,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setNormalProducts: (state, action) => {
      state.normal_products = action.payload;
    },
    setRentalProducts: (state, action) => {
      state.rental_products = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = true;
    },
  },
});

// Get All stripe products
export const getStripeProductsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/product/get_strip_products"
    );
    dispatch(setProducts(response.data.products));
  } catch (err) {
    throw new Error(err);
  }
};

// Get All products
export const getProductsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/product/get_all_products"
    );
    dispatch(setProducts(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get All Rental products
export const getRentalProductsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/product/get_products/rental"
    );
    dispatch(setRentalProducts(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get All Normal products
export const getNormalProductsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/product/get_products/normal"
    );
    dispatch(setNormalProducts(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get product by id
export const getProductAsync = (product_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/product/" + product_id
    );
    dispatch(setProduct(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get product by short name
export const getProductBySnAsync = (sn) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/product/get_product_by_sn/" + sn
    );
    dispatch(setProduct(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const {
  setProduct,
  setProducts,
  setNormalProducts,
  setRentalProducts,
  setLoading,
} = productSlice.actions;
export const showProducts = (state) => state.product.products;
export const showNormalProducts = (state) => state.product.normal_products;
export const showRentalProducts = (state) => state.product.rental_products;
export const showProduct = (state) => state.product.product;
export const showLoading = (state) => state.product.loading;
export default productSlice.reducer;
