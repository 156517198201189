import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getBookingSettingAsync,
  showBookingsetting,
} from "../../app/bookSettingSlice";
import { Link } from "react-router-dom";

const Footer = ({ bookAppointment }) => {
  const dispatch = useDispatch();
  const booking_setting = useSelector(showBookingsetting);

  const [version, setVersion] = useState("");
  const [bookingSetting, setBookingSetting] = useState();

  useEffect(() => {
    dispatch(getBookingSettingAsync());

    axios
      .get(process.env.REACT_APP_API_BASE_URL + "api/version/latest")
      .then((res) => {
        setVersion(res.data.version);
      })
      .catch((error) => console.error("Error getting version number", error));
  }, [dispatch]);

  useEffect(() => {
    if (booking_setting) {
      setBookingSetting(booking_setting);
    }
  }, [booking_setting]);

  const formatTime = (time) => {
    if (!time) {
      return ""; // Return an empty string if the time is undefined
    }

    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);

    const amPm = hour >= 12 ? "PM" : "AM";
    const displayHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

    return `${displayHour}:${minute === 0 ? "00" : minute} ${amPm}`;
  };

  return (
    <footer className="w-full px-[20px] py-[45px] bg-[#151515] text-white text-[16px] leading-[18.75px]">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="border-b-2 pb-4">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:basis-1/4">
              <div className="w-full text-[20px] font-bold leading-[23.44px]">
                Working Days
              </div>
              <div className="mb-4 w-full flex items-center mt-[37px]">
                <div className="basis-1/2">7 days a week</div>
                <div className="basis-1/2">
                  {formatTime(bookingSetting?.startOperationHours)} -{" "}
                  {formatTime(bookingSetting?.endOperationHours)}
                </div>
              </div>
            </div>
            <div className="w-full lg:basis-1/2 flex mt-[37px] lg:mt-[0px]">
              <div className="basis-1/2">
                <div className="w-full text-[20px] font-bold leading-[23.44px]">
                  Useful Links
                </div>
                <ul className="w-full items-center mt-[37px]">
                  <li className="mb-4">
                    <Link to="/" className=" hover:underline">
                      Home
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link to="/service-areas" className=" hover:underline">
                      Service Areas
                    </Link>
                  </li>
                  <li className="mb-4">
                    <a href="/gallery" className=" hover:underline">
                      Gallery
                    </a>
                  </li>
                  <li className="mb-4">
                    <Link to="/manual" className=" hover:underline">
                      Manuals
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link to="/faq" className=" hover:underline">
                      F.A.Q
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="basis-1/2">
                <div className="w-full text-[20px] font-bold leading-[23.44px]">
                  Contact
                </div>
                <div className="w-full items-center mt-[37px]">
                  <div className="mb-4 w-full flex mt-[7px]">
                    <span>SF Bay Area</span>
                  </div>
                  <div className="mb-4 w-full flex mt-[7px]">
                    <Link to="tel:415-712-0894" className="underline">
                      (415) 712-0894
                    </Link>
                  </div>
                  <div className="mb-4 w-full flex mt-[7px] gap-2">
                    <Link
                      to="https://www.instagram.com/besthandymanever"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/img/instagram.png"
                        className="w-8"
                        alt="instagram"
                      ></img>
                    </Link>
                    <Link
                      to="https://www.linkedin.com/in/best-handyman-ever"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/img/linkedin.png"
                        className="w-8"
                        alt="linkedin"
                      ></img>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:basis-1/4 mt-[37px] lg:mt-[0px]">
              <ul className="w-full items-center mt-[37px] sm:mt-[200px]">
                <li
                  className="mb-4 w-full bg-[#151515] px-auto py-[15px] text-white font-bold mt-[21px] text-center border-2 border-[#F8981D] hover:bg-[#F8981D] hover:cursor-pointer"
                  onClick={bookAppointment}
                >
                  Request With Online Form
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row justify-between items-center mt-[21px] text-[14px] leading-[16px]">
          <div className="mb-4 lg:mb-0 text-center">
            <Link
              to="https://www.bbb.org/us/ca/san-francisco/profile/handyman/best-handyman-ever-1116-943008/#sealclick"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://seal-goldengate.bbb.org/seals/blue-seal-200-65-bbb-943008.png"
                alt="Best Handyman Ever BBB Business Review"
              />
            </Link>
          </div>
          <div className="mb-4 lg:mb-0 text-center">
            <Link
              to="https://www.privacypolicies.com/live/411fba1c-2593-4c19-b971-6c90226ba805"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {" "}
              Privacy Policy |{" "}
            </Link>
            <Link to="/terms" className="underline">
              {" "}
              Terms & Conditions |{" "}
            </Link>
            <Link
              to="https://www.privacypolicies.com/live/a2bc055e-be96-4127-8665-19a1365e44d5"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {" "}
              Disclaimer
            </Link>
          </div>
          <div className="text-center">
            {version} Copyright © 2024{" "}
            <span className="font-bold">Best Handyman Ever.</span> All rights
            reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
